import react, { useState, useEffect } from 'react'
import { EnrollUsersTableHeading } from '../../Constants'
import { getAllEnrolledCourses, getAllEnrolledUserReq, searchByCourse, searchByDateData } from '../../api'
import UsersCardData from 'Components/UsersCard'

const selectUserData = [{ label: 10, value: 10 },
{ label: 20, value: 20 },
{ label: 50, value: 50 },
{ label: 100, value: 100 },
{ label: 500, value: 500 },
{ label: 1000, value: 1000 },
{ label: 2000, value: 2000 },
{ label: 5000, value: 5000 },
  // { label: 10000, value: 10000 }, 
]


const AllEnrollForms = () => {
  const [dataTags, setDataTags] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [paginationValueTags, setPaginationValueTags] = useState({
    currentPageTags: 1,
    totalSizeTags: 20,
  })
  const { currentPageTags, totalSizeTags } = paginationValueTags
  const [rowData, setRowData] = useState([])
  const [relatedCourses, setRelatedCourses] = useState([])
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [selectUserOption, setSelectUserOption] = useState([])
  const [selectedLimit, setSelectedLimit] = useState([])
  const [availableCourseList, setAvailableCourseList] = useState([])

  const getCategoryData = async (queryParams) => {
    try {
      let payload = {};
      if (startDate) {
        payload['startDate'] = formatDate(startDate)
      }
      else {//if there is no date
        const startOfDay = new Date(); // Create a new Date object with the current date and time
        startOfDay.setHours(0, 0, 0, 1);
        const isoStringStart = startOfDay.toISOString();
        payload['startDate'] = isoStringStart
      }

      if (endDate) {
        payload['endDate'] = formatEndDate(endDate)
      }
      else {//if there is no date
        const endOfDay = new Date(); // Create a new Date object with the current date and time
        endOfDay.setHours(23, 59, 59, 999);
        const isoStringEnd = endOfDay.toISOString();
        payload['endDate'] = isoStringEnd
      }
      payload['page'] = queryParams?.page
      payload['limit'] = queryParams?.limit
      setIsLoading(true)
      const { data } = await getAllEnrolledUserReq(queryParams, payload)
      setPaginationValueTags({
        currentPageTags: queryParams.page,
        totalSizeTags: data.count,
      })
      setDataTags(data.data)
      setIsLoading(false)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }
  useEffect(async (selectedLimit) => {

    const startOfDay = new Date(); // Create a new Date object with the current date and time
    startOfDay.setHours(0, 0, 0, 1);
    const isoStringStart = startOfDay.toISOString();
    setStartDate(isoStringStart)

    const endOfDay = new Date(); // Create a new Date object with the current date and time
    endOfDay.setHours(23, 59, 59, 999);
    const isoStringEnd = endOfDay.toISOString();
    setEndDate(isoStringEnd)
    getCategoryData({ page: 1, limit: selectedLimit ? selectedLimit : 10 })
    let data = await getAllEnrolledCourses({})
    let coursesList = [{ label: 'All', value: '' }];
    data?.data?.data?.forEach(element => {
      coursesList.push({ label: element.courseName, value: element.courseName })
    });
    setAvailableCourseList(coursesList);

  }, [])

  const handleStartDateChange = (date) => {

    setStartDate(date)
  }

  const handleEndDateChange = (date) => {
    setEndDate(date)
  }
  const formatDate = (date) => {
    const iso8601Regex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z?)$/;
    // Test the input string against the regular expression
    if (iso8601Regex.test(date)) {
      return date
    }
    else {
      return date.toISOString()//.slice(0, 10)}
    }
  }

  const formatEndDate = (date) => {
    const iso8601Regex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z?)$/;
    // Test the input string against the regular expression
    if (iso8601Regex.test(date)) {
      return date
    }
    else {
      const endOfDay = date;
      endOfDay.setHours(23, 59, 59, 999);
      return endOfDay.toISOString()//.slice(0, 10)}
    }
  }

  const handleDateFilter = async () => {
    const formattedStartDate = formatDate(startDate)
    const formattedEndDate = formatEndDate(endDate)

    try {
      const { data } = await searchByDateData({
        startDate: formattedStartDate,
        endDate: formattedEndDate,
      })

      setRowData(data.data)
      setDataTags(data.data)
    } catch (error) {
      console.error(error)
    }
  }

  // const handleSelectUsersListChangeAp = async(selectedOption,selectedCourse) => {
  //   setSelectedLimit(selectedOption)
  //   const courseId = selectedCourse ? selectedCourse.label : null

  //   setPaginationValueTags({
  //     ...paginationValueTags,
  //     totalSizeTags: selectedLimit,
  //   })

  //   await getCategoryData({ page: 1, limit: selectedOption.value })
  //   await searchByCourse({ 
  //     courseName: courseId })
  // }

  const handleSelectUsersListChange = (selectedOption) => {
    setSelectedLimit(selectedOption)
  }

  const handleRelatedCoursesChange = (selectedCourse) => {
    setRelatedCourses(selectedCourse)
  }

  const handleRelatedCoursesChangeAp = async (selectedCourse, page, limit) => {
    setRelatedCourses(selectedCourse)
    try {
      const courseId = selectedCourse ? selectedCourse.label : null
      let payload = { courseName: courseId };
      payload['courseName'] = payload['courseName'] != 'All' ? payload['courseName'] : null
      if (startDate) {
        payload['startDate'] = formatDate(startDate)
      }

      if (endDate) {
        payload['endDate'] = formatEndDate(endDate)
      }
      payload['page'] = page
      payload['limit'] = limit
      const { data } = await searchByCourse({ search: payload, page, limit })
      setRowData(data.data)
      setDataTags(data.data)
    } catch (error) {
      console.error(error)
    }
  }

  const handleConfirmClick = async () => {
    const courseId = relatedCourses ? relatedCourses.label : null
    const limitValue = selectedLimit ? selectedLimit.value : 10

    if (courseId && limitValue) {
      await handleRelatedCoursesChangeAp(relatedCourses, 1, limitValue)
    } else if (courseId) {
      await handleRelatedCoursesChangeAp(relatedCourses, 1, 10)
    } else if (limitValue) {
      await getCategoryData({ page: 1, limit: limitValue })
    } else {
      await getCategoryData({ page: 1, limit: 10 })
    }
  }


  const handleTablePageChangeTags = (type, { page, selectedCourse }) => {
    getCategoryData({ page, limit: selectedCourse ? selectedCourse : 10 })
    setPaginationValueTags({
      ...paginationValueTags,
      currentPageCat: page,
    })
  }

  const handleAllExportToCSV = () => {

    const csvDataAll = rowData.map((item) => ({
      Name: item.personalDetails.name,
      Email: item.personalDetails.emailId,
      Phone: `=""${item.personalDetails.phone}""`,
      Gender: item.personalDetails.gender,
      Country: item.personalDetails.country,
      City: item.personalDetails.city,
      Nationality: item.personalDetails.nationality,
      CourseMode: item.courseDetails.mode,
      CourseName: item.courseDetails.courseName,
      Date: (item?.startDate && item?.endDate) ? `${item.startDate} to ${item.endDate} - ${item.courseDetails?.date}` : item.courseDetails.date,
      Payment: (item?.courseDetails?.courseId == "satsang" || item?.courseDetails?.courseId == "samattvam" || item?.courseDetails?.courseId == "yoga-by-the-bay" || item?.courseDetails?.courseId == "fullmoon-meditation") ? 'Registered' : item.isPaid,
      'Payment Date': item.createdAt
    })
    )
    return csvDataAll
  }

  return (
    <>
      <UsersCardData
        heading="Enrolled Users"
        tableData={dataTags}
        tableHeading={EnrollUsersTableHeading}
        handleTablePageChange={handleTablePageChangeTags}
        handleConfirmClick={handleConfirmClick}
        page={currentPageTags}
        sizePerPage={20}
        totalSize={totalSizeTags}
        isLoading={isLoading}
        allTags={true}
        rowData={rowData}
        setRowData={setRowData}
        relatedCourses={relatedCourses}
        handleRelatedCoursesChange={handleRelatedCoursesChange}
        startDate={startDate}
        endDate={endDate}
        handleEndDateChange={handleEndDateChange}
        handleStartDateChange={handleStartDateChange}
        handleDateFilter={handleDateFilter}
        handleAllExportToCSV={handleAllExportToCSV}
        selectUserData={selectUserData}
        handleSelectUsersListChange={handleSelectUsersListChange}
        selectedLimit={selectedLimit}
        availableCourseList={availableCourseList}
      />
    </>
  )
}

export default AllEnrollForms
