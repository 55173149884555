import ClverTapDelete from '../Views/Delete'
import ClverTapUploads from '../Views/Uploads'
import WebPush from '../Views/webPush'

const ClverTapRoutes = [

  {
    Component: ClverTapUploads,
    path: '/all',
    exact: false,
    id: 'dashboardCleverTapUpload',
    routePath: '/dashboard/clever-tap/all',
    name: 'Upload',
  },
  {
    Component: WebPush,
    path: '/config',
    exact: true,
    id: 'dashboardCleverTapSetup',
    routePath: '/dashboard/clever-tap/config',
    name: 'Web Push',
  },
  {
    Component: ClverTapDelete,
    path: '/delete',
    exact: false,
    id: 'dashboardCleverTapUpload',
    routePath: '/dashboard/clever-tap/delete',
    name: 'Delete',
  },


]

export default ClverTapRoutes

