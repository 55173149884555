import Table from 'Components/Table'
import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, Col, Card, CardBody, Button } from 'reactstrap'
import { getAllMetaData } from '../../api'
import { MetaHeading } from '../../constants/utils'
import MetaDataPage from 'Components/MetaDataPage'

const AllMetadata = ({
  metaRowData,
  metaPage,
  metaLimit,
  metaCount,
  handleEditTablePageChange,
  PageMetaHeading
}) => {
  const { id } = useParams()
  const [rowData, setRowData] = useState([])
  console.log(rowData, 'you')
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
  })
  const [ count, setCount] = useState()
  const { page, limit } = pagination
  const navigate = useNavigate()

  const handleEditMeta = (id) => {
    navigate('/dashboard/meta-data/edit/' + id)
  }

  const editContentFormatter = (cell, row) => {
    console.log(row._id, 'rowwww')
    return (
      <i
        className="ri-file-edit-fill text-info h4 cursor-pointer mr-2"
        onClick={() => handleEditMeta(row.pagePath)}
      />
    )
  }

  const handleEditMetaClick = (cell, row) => {
    return (
      <div style={{ textAlign: 'center' }} key={row._id}>
        <Button
          color="primary"
          className="waves-effect waves-light"
          style={{ width: '100%' }}
          onClick={() => handleEditMeta(row.pagePath)}
        >
          Edit
        </Button>
      </div>
    )
  }

  useEffect(() => {
    // MetaHeading.push({
    //   text: 'Actions',
    //   dataField: 'edit',
    //   isDummyField: true,
    //   headerStyle: { textAlign: 'center', width: '10%' },
    //   formatter: editContentFormatter,
    // })
    MetaHeading.push({
      text: 'Edit Meta Data',
      dataField: 'edit',
      isDummyField: true,
      headerStyle: { textAlign: 'center', width: '10%' },
      formatter: handleEditMetaClick,
    })
    return () => {
      // MetaHeading.pop()
      MetaHeading.pop()
    }
  }, [])
  console.log(MetaHeading, 'kill')

  const handleTablePageChange = (type, { page, sizePerPage }) => {
    setPagination({ page: page, limit: sizePerPage })
  }
  const handleMetaTableData = async() => {
    try {
      const { data } = await getAllMetaData({ page:page, limit:100 , objectType :'COURSE' })
      console.log(data.count,'zooo')
      console.log(count,'count')
      setCount(data.count)
      if (data.data) {
        var result = data.data.map(function(el, i) {
          var o = Object.assign({}, el)
          o.sNo = i + 1 + page * 10 - 10
          return o
        })
        setRowData(result)
      }
    } catch (error) {
      console.log(error)
    }
   
  }

  useEffect(() => {
    handleMetaTableData()
  }, [pagination])

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <Row>
              <Col xs={12}>
                <div className="page-title-box d-flex align-items-center justify-content-between">
                  <h3 className="m-0">Course Meta Data</h3>
                </div>
              </Col>
            </Row>
            <Row>
              <Table
                rows={rowData}
                columns={MetaHeading}
                onTableChange={handleTablePageChange}
                page={page}
                sizePerPage={limit}
                totalSize={count}
                // isLoading={isLoading}
              />
            </Row>
            <Row>
              <MetaDataPage 
                rows={metaRowData}
                columns={PageMetaHeading}
                page={metaPage}
                sizePerPage={metaLimit}
                totalSize={metaCount}
                onTableChange={handleEditTablePageChange}
              />
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

export default AllMetadata
