import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Card, CardBody, FormGroup, Label, Button } from 'reactstrap'
import { AvForm, AvField } from 'availity-reactstrap-validation'
import Select from 'react-select'
import SweetAlert from 'react-bootstrap-sweetalert'
import { createMetaData } from '../../api'
import { pageList } from '../../constants/utils'

const PageMetaData = () => {
  const [showCreateAlert, setShowCreateAlert] = useState(false)
  const [courseName, setCourseName] = useState('')
  const [metaTitle, setMetaTitle] = useState('')
  const [metaDescription, setMetaDescription] = useState('')
  const [metaPara, setMetaPara] = useState('')

  const [metaKeyword, setMetaKeyword] = useState('')
  const navigate = useNavigate()

  const date = new Date()
  console.log('courses')

  const handleSubmit = async () => {
    try {
      await createMetaData({
        pagePath: courseName,
        meta: `  <title>${metaTitle}</title>
       <meta name="description" content="${metaDescription}" />
       <meta name="keywords" content="${metaKeyword}"/>
       <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
       <link rel="canonical" href="https://theyogainstitute.org/${courseName}" />
       <meta property="og:locale" content="en_US" />
       <meta property="og:type" content="article" />
       <meta property="og:title" content="${metaTitle}" />
       <meta property="og:description" content="${metaDescription} " />
       <meta property="og:url" content="https://theyogainstitute.org/${courseName}" />
       <meta property="og:site_name" content="The Yoga Institute" />
       <meta property="article:publisher" content="https://www.facebook.com/theyogainstitutemumbai/" />
       <meta property="article:published_time" content="${date.toISOString()}" />
       <meta property="article:modified_time" content="${date.toISOString()}" />
       <meta property="og:image" content="https://ecom-static-site-prod.s3.ap-south-1.amazonaws.com/Home/tyi.png" />
         <meta property="og:image:width" content="1200" />
         <meta property="og:image:height" content="628" />
       <meta name="twitter:label1" content="Est. reading time">
         <meta name="twitter:data1" content="10 minutes">
       `,
        objectType: 'PAGE',
        pTag: metaPara
      },
        navigate('/dashboard/meta-data/all')
      )
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <Row>
                <Col xs={12}>
                  <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h3 className='m-0'>Create Page Meta Data</h3>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Page</Label>
                    <AvForm>
                      <Select
                        type='text'
                        name='page'
                        placeholder='Select Page'
                        value={pageList.find(item => item.value === courseName)}
                        options={pageList}
                        onChange={(e) => { setCourseName(e.value) }}
                        errorMessage='Please Select Page'
                        validate={{ required: { value: true } }}
                      />
                    </AvForm>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Title</Label>
                    <AvForm>
                      <AvField
                        type='text'
                        name='title'
                        placeholder='Enter Meta Title'
                        onChange={(e) => { setMetaTitle(e.target.value) }}
                        value={metaTitle}
                        errorMessage='Please enter title'
                        validate={{ required: { value: true } }}
                      />
                    </AvForm>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Description</Label>
                    <AvForm>
                      <AvField
                        type='text'
                        name='description'
                        value={metaDescription}
                        onChange={(e) => { setMetaDescription(e.target.value) }}
                        placeholder='Enter Discription'
                        errorMessage='Please enter discription'
                        validate={{ required: { value: true } }}
                      />
                    </AvForm>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label htmlFor="metaPara">Paragraph</Label>
                    <AvForm>
                      <AvField
                        name="metaPara"
                        placeholder="Enter Meta Paragraph"
                        value={metaPara}
                        onChange={(e) => {
                          setMetaPara(e.target.value)
                        }}
                        type="text"
                        errorMessage="Paragraph is required"
                        className="form-control"
                        validate={{ required: { value: false } }}
                        id="metaPara"
                      />
                    </AvForm>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Keywords</Label>
                    <AvForm>
                      <AvField
                        type='text'
                        name='keywords'
                        value={metaKeyword}
                        onChange={(e) => { setMetaKeyword(e.target.value) }}
                        placeholder='Enter Keywords'
                        errorMessage='Please enter keywords'
                        validate={{ required: { value: true } }}
                      />
                    </AvForm>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    color='primary'
                    className="w-100 mt-4"
                    type='submit'
                    onClick={() => setShowCreateAlert(true)}
                  >Create Page Meta Data</Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {showCreateAlert ? <SweetAlert
        title="Create New Page Meta Data"
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Create"
        reverseButtons={true}
        onConfirm={handleSubmit}
        onCancel={() => { setShowCreateAlert(false) }}
      ></SweetAlert> : null}
    </div>
  )
}

export default PageMetaData